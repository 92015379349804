import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import SetMetaTitleDescripionAndKeyword from './Blog/SetMetaAndTitle';


const PortfolioSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [featuredPost, setFeaturedPost] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading
  const history = useNavigate();
  const itemsPerPage = 6;

  useEffect(() => {
    const apiUrl = `https://api.sarinformatics.in/public/pages/blogs/recent_projects.php?page=${currentPage}&per_page=${itemsPerPage}`;
    const recentPostsUrl = `https://api.sarinformatics.in/public/pages/blogs/recent_post.php?page=1&per_page=1`;

    Promise.all([
      fetch(apiUrl).then((response) => response.json()),
      fetch(recentPostsUrl).then((response) => response.json())
    ])
      .then(([data, recentData]) => {
        setLoading(false); // Set loading to false when data is fetched
        if (data.status === 200) {
          setBlogs(data.data);
          setTotalCount(data.total_count);
        }
        if (recentData.status === 200) {
          setFeaturedPost(recentData.data[0]);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      });
  }, [currentPage]);

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function viewBlog(blog) {
    history.push(`/article/${blog.id}/${blog.title}`);
  }

  function renderPagination() {
    if (blogs.length === 0) return null;

    const totalPages = Math.ceil(Number(totalCount) / itemsPerPage);
    const pageNumbers = [];

    if (currentPage > 1) {
      pageNumbers.push(
        <button key="prev" className="page-button mr-2 px-4 py-2" onClick={() => handlePageChange(currentPage - 1)}>
          Previous
        </button>
      );
    }
    pageNumbers.push(
      <button key={currentPage} className={`page-button active px-3 py-2`} onClick={() => handlePageChange(currentPage)}>
        {currentPage}
      </button>
    );
    if (currentPage < totalPages) {
      pageNumbers.push(
        <button key="next" className="page-button ml-2 px-4 py-2" onClick={() => handlePageChange(currentPage + 1)}>
          Next
        </button>
      );
    }

    return (
      <div className="pagination">
        {pageNumbers}
      </div>
    );
  }

  function displayBlogs() {
    return blogs.map((blog, index) => (
      <div key={index} className="col-lg-4 col-md-6">
        <div className="portfolio-box shadow">
          <img src={blog.img_url} alt="" title={blog.title} className="img-fluid" />
          <Link to={`/projects/${blog.id}/${blog.encoded_title}`} style={{ textDecoration: 'none' }} onClick={() => viewBlog(blog)}>
            <div className="portfolio-info">
              <div className="caption">
                <h4>{blog.title}</h4>
                <p>{blog.short_content}</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
    ));
  }

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">

      <SetMetaTitleDescripionAndKeyword
pageTitle='Portfolio'
pageDescription='The portfolio section serves as a showcase for our projects, highlighting our work samples, case studies, and project highlights.'
pageKeywords='Portfolio Showcase, Project Highlights, Case Studies, Work Samples, Project Portfolio'
/>
        <div className="row text-center mt-5">
          <h1 className="display-3 fw-bold text-capitalize">Portfolio</h1>
          <div className="heading-line"></div>
        </div>
        <div className="row" id="blogCards">
          {loading ? ( // Check if data is loading
            <div className="text-center">
              <i className="fas fa-spinner fa-spin fa-3x"></i> {/* Font Awesome spinner icon */}
              <p>Loading...</p>
            </div>
          ) : (
            <>
              {displayBlogs()}
            </>
          )}
        </div>
        <div className="pagination-container">
          {renderPagination()}
        </div>
      </div>
    </section>
  );
};

export default PortfolioSection;
