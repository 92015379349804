import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer py-5">
      {/* START THE COPYRIGHT INFO */}
      <div className="footer-copyright text-center text-white py-4">
        <div className="container">
          <p className="mb-0">
            Copyright © 2024 <a href="#">sar informatics</a> All right Reserved | <Link to="/about">About</Link> | <Link to="/contact">Contact</Link> | <Link to="/privacy">Privacy</Link> | <Link to="/disclaimer">Disclaimer</Link> | <Link to="/faq">FAQ</Link>
          </p>
        </div>
      </div>

      {/* START THE SOCIAL MEDIA CONTENT */}
      <div className="footer-social text-center pt-4">
        <div className="container">
          <ul className="list-inline mb-0">
            
            <li className="list-inline-item">
              <a href="#" style={{ fontSize: '1.5rem' }}><i className="fab fa-github"></i></a>
            </li>
            <li className="list-inline-item">
              <a href="#" style={{ fontSize: '1.5rem' }}><i className="fab fa-linkedin"></i></a>
            </li>
            
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
