import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { FaFacebookF, FaWhatsapp, FaShare } from 'react-icons/fa';
import HTMLRenderer from './Renderer';
import './BlogPage.css';
// import './assets/css/style.css';
// import './assets/css/fontawesome.css';
import BuyNowLiveViewButton from '../BuyNowLiveViewBtn';
import PptViewer from '../Blog/ShowPresentation';


const Blog = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);



  useEffect(() => {
    // Fetch the data for the specified blog post based on the id parameter
    fetch(`https://api.sarinformatics.in/public/blogviewer.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setBlog(data.data);
          setSuggestions(data.suggestions);
          if (data.tags && data.tags.tags) {
            const tagsArray = data.tags.tags.split(', ');
            setTags(tagsArray);
          }
        } else {
          console.error('Error fetching blog data');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  if (!blog) {
    return <div>Blog Not Found</div>;
  }

  function copyLink() {
    const link = window.location.href;

    const dummyLink = document.createElement('input');
    document.body.appendChild(dummyLink);
    dummyLink.value = link;
    dummyLink.select();
    document.execCommand('copy');
    document.body.removeChild(dummyLink);

    const copyButton = document.querySelector('#copyLinkButton');
    copyButton.textContent = 'Link Copied';
    copyButton.disabled = true;

    setTimeout(function () {
      copyButton.textContent = 'Copy Link';
      copyButton.disabled = false;
    }, 5000);
  }

  const htmlString = blog.content;

  return (
    <div className="container my-5">
      <div className="row">


        <div className="col-md-12">
          {/* Main Content */}
          <h1 className="blogtitle">{blog.title}</h1>
          <p className="blog-date">{blog.updated_at}</p>
          <h2 className="blogsubtitle">{blog.subtitle}</h2>

          <PptViewer pptxUrl={blog.ppt_url} />

          
          <div className="blog-content" id="paragraph">
            <HTMLRenderer htmlString={htmlString} />
          </div>



          <div className="my-4">
            <h6>Share this Project:</h6>
            <div className="my-3">
              <a
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  window.location.href
                )}`}
                className="sharebutton"
              >
                <FaFacebookF />
              </a>

              <a
                href={`https://api.whatsapp.com/send?text=Check%20out%20this%20awesome%20blog%20post:%20${blog.title}%20${window.location.href}`}
                target="_blank"
                rel="noopener noreferrer"
                className="sharebutton"
              >
                <FaWhatsapp />
              </a>
              <a
                className="sharebutton"
                onClick={copyLink}
                id="copyLinkButton"
              >
                <FaShare />
              </a>
            </div>
          </div>





        </div>

        <div className="p-3 sidebar">
          <BuyNowLiveViewButton projecturl={blog.project_url} />
          {/* Right Ad Component Placeholder */}
          {/* <h4 className='mt-4'>Thumbnail</h4>
          <img
            src={blog.img_url}
            className="projectimage mt-4 mb-3"
            alt="Banner"
          /> */}

          <div className='my-4 bg-light'>

            Google Ads
          </div>



        </div>
      </div>


    </div>
  );
};

export default Blog;
