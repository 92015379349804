import React, { useState, useEffect } from 'react';
import './ServicePopup.css'; // Import your CSS file for styling
import Img from './businesspromotion.png';

const ServicePopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // Show the popup once after 10 seconds
    const timer = setTimeout(() => {
      setIsOpen(true);
    }, 1200000);

    // Clear the timer to prevent subsequent popups
    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsOpen(false);
  };

  const redirectToContactPage = () => {
    window.location.href = 'https://sarinformatics.in/contact';
  };

  return (
    <div>
      {isOpen && (
        <div className={`service-popup-overlay ${isOpen ? 'open' : ''}`}>
          <div className={`service-popup ${isOpen ? 'open' : ''}`}>
            <div className="service-popup-content">
              <div className='row'>
                <div className='col-lg-6 col-md-12'>
                  <img src={Img} alt="Service Image" className="service-image" />
                </div>
                <div className='col-lg-6 col-md-12 py-5'>
                  <h2>Our Services</h2>
                  <p>Explore our range of services tailored to meet your needs:</p>
                  <ul>
                    <li>Custom Web Development</li>
                    <li>WordPress Development</li>
                    <li>SEO Optimization</li>
                  </ul>
                  <button onClick={redirectToContactPage} className="popup-contact-button m-2">Contact Us</button>
                  <button onClick={closePopup} className="popup-close-button">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServicePopup;
