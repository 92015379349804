import React, { useEffect } from 'react';

const ServicesSection = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section id="services" className="services">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold">Services</h1>
          <div className="heading-line mb-1"></div>
        </div>
       

        <div className="container">
        <div className="bg-white my-5">
          <h2 className="fw-bold text-capitalize">

          </h2>
          <h2 className="display-6 fw-bold text-capitalize text-dark mt-3">Our Services Range From Initial Design To Deployment Anywhere Anytime</h2>
          <p className="fw-light"><span className="display-2--description lh-base text-dark my-3">Web development creates functional and visually appealing websites. WordPress simplifies website management with its user-friendly CMS. SEO optimizes websites to rank higher in search engine results, driving organic traffic. Together, they build a strong online presence, attracting relevant visitors and achieving business goals.</span>

          </p>
        </div>
          {/* START THE MARKETING CONTENT */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <h3 className="display-3--title mt-1">Wordpress</h3>
                <p className="lh-lg"><span className="display-2--description lh-base text-dark my-3">WordPress, a popular content management system, streamlines website management by providing a user-friendly interface and a wide range of themes and plugins. It empowers users to customize their websites without extensive coding knowledge, making it accessible to a broad audience.</span>
                </p>
                <button type="button" className="rounded-pill btn-rounded border-primary">
                  Know more <span><a href="https://sarinformatics.in/article/1713147722/WordPress-Empowering-Your-Online-Presence-with-Ease" target='_blank'><i className="fas fa-arrow-right"></i></a></span>
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end d-none d-sm-block">
              <div className="services__pic">
                <img src="images/services/service-1.png" alt="marketing illustration" className="img-fluid" />
              </div>
            </div>
          </div>

          {/* START THE WEB DEVELOPMENT CONTENT */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-start d-none d-sm-block">
              <div className="services__pic">
                <img src="images/services/service-2.png" alt="web development illustration" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <h3 className="display-3--title mt-1">Web Development</h3>


                <p className="lh-lg"><span className="display-2--description lh-base text-dark my-3">Web development encompasses the creation and maintenance of websites, ensuring they are functional, user-friendly, and visually engaging. It involves utilizing various programming languages and technologies to bring designs to life and optimize performance across different devices.</span>
                </p>
                <button type="button" className="rounded-pill btn-rounded border-primary">
                  Know more <span><a href="https://sarinformatics.in/article/1713147617/Web-Development-Building-the-Foundations-of-Digital-Success" target='_blank'><i className="fas fa-arrow-right"></i></a></span>
                </button>
              </div>
            </div>
          </div>

          {/* START THE CLOUD HOSTING CONTENT */}
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4">
              <div className="services__content">
                <h3 className="display-3--title mt-1">SEO</h3>
                <p className="lh-lg"><span className="display-2--description lh-base text-dark my-3">SEO, or Search Engine Optimization, is the practice of optimizing websites to rank higher in search engine results pages (SERPs). It involves strategies such as keyword research, on-page optimization, link building, and content creation to attract organic traffic and improve visibility.</span>
                </p>
                <button type="button" className="rounded-pill btn-rounded border-primary" >
                  Know more <span><a href='https://sarinformatics.in/article/1713148097/SEO-Navigating-the-Path-to-Online-Visibility' target='_blank'><i className="fas fa-arrow-right"></i></a></span>
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 services mt-4 text-end d-none d-sm-block">
              <div className="services__pic">
                <img src="images/services/service-3.png" alt="cloud hosting illustration" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
