import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';
import { Carousel } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';

const TopicSlider = () => {
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get('https://api.sarinformatics.in/public/pages/blogs/get_topics.php')
            .then(response => {
                if (response.data.status === 200) {
                    setTopics(response.data.data);
                } else {
                    console.error("Failed to fetch topics.");
                }
                setLoading(false);
            })
            .catch(error => {
                console.error("Error:", error);
                setLoading(false);
            });
    }, []);



    return (
        <>
        <div className='mb-4'>
        {topics.map((topic, index) => (

            
            <span key={index}>
                
                <Badge bg="primary" text='light'><Link to={`/topic/${topic.topic_name}`} className='text-white'>{topic.topic_name}</Link></Badge>
                

            </span>
        ))}

        </div>
        </>
    );
};

export default TopicSlider;
