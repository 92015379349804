import React, { useState, useEffect } from 'react';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const slideNext = () => {
    const newIndex = (activeIndex + 1) % testimonials.length;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.sarinformatics.in/public/pages/testimonials.php');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const responseData = await response.json();
        if (responseData.status === 200) {
          setTestimonials(responseData.data);
        } else {
          throw new Error(responseData.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(slideNext, 3000);
    return () => clearInterval(interval);
  }, [activeIndex, testimonials]);

  return (
    <section id="testimonials" className="testimonials">
      {/* SVG Code and other HTML content */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#fff" fillOpacity="1" d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,117.3C672,117,768,203,864,202.7C960,203,1056,117,1152,117.3C1248,117,1344,203,1392,245.3L1440,288L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"></path>
      </svg>

      <div className="container">
        <div className="row align-items-center">
        
          <h1 className="display-3 fw-bold text-center text-white mb-5">Testimonials</h1>

        
          <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {testimonials.map((testimonial, index) => (
                <div key={index} className={`carousel-item ${index === activeIndex ? 'active' : ''}`}>
                  <div className="testimonials__card">
                    <p className="lh-lg">
                      <i className="fas fa-quote-left"></i>
                      {testimonial.quote}
                      <i className="fas fa-quote-right"></i>
                      <div className="ratings p-1">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </p>
                  </div>
                  <div className="testimonials__picture">
                    <img src={testimonial.image} alt={`client-${index + 1}`} className="rounded-circle img-fluid" />
                  </div>
                  <div className="testimonials__name">
                    <h3>{testimonial.name}</h3>
                    <p className="fw-light">{testimonial.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* More SVG Code and other HTML content */}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill="#fff" fillOpacity="1" d="M0,96L48,128C96,160,192,224,288,213.3C384,203,480,117,576,117.3C672,117,768,203,864,202.7C960,203,1056,117,1152,117.3C1248,117,1344,203,1392,245.3L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg>
    </section>
  );
};

export default Testimonials;

