import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './TopicList.css';
import './Codebook.css';
import TopicSlider from './TopicSlider';
import SearchComponent from '../Search';

const TopicBlogList = () => {
  const { topicname } = useParams();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://api.sarinformatics.in/public/pages/blogs/get_topic_by_topic_name.php?topic_name=${topicname}`);
        const data = await response.json();
        if (data.status === 200 && data.total_count > 0) {
          setBlogs(data.data);
        } else {
          console.error('Error fetching blog data');
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [topicname]);

  if (loading) {
    return <div className="text-center"> <i className="fas fa-spinner fa-spin fa-3x"></i> Loading...</div>;
  }

  if (blogs.length === 0) {
    return <div>No blogs found for this topic</div>;
  }

  return (
    <div className="container my-5">
      <h1 className="display-6 text-capitalize text-dark my-3">TOPIC → {topicname}</h1>
      <hr />
      <div className="row mt-5">
        <div className="col-md-9 mt-3">
          <ul className="list-unstyled">
            {blogs.map((blog) => (
              <div className="mb-3" key={blog.id}>
                <Link to={`/${blog.id}/${blog.encoded_title}`} className="text-decoration-none">
                  <div className="row g-2 codeblock">
                    <div className="col-md-4 col-sm-12">
                      <img src={blog.img_url} className="img-fluid rounded-start" alt={blog.title} />
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <div className="card-body">
                        <h5 className="card-title" style={{ color: 'black' }}>{blog.title}</h5>
                        <p className="card-text">{blog.formatted_date}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </ul>
        </div>
        <div className="col-md-3 sidebar mt-3">
          <SearchComponent/>
        <h5 className='px-2 mb-3 text-primary'>Categories</h5>
          <div className='px-2'>
            <TopicSlider />
          </div>
          <div className="ad-placeholder">Right Ad</div>
        </div>
      </div>
    </div>
  );
};

export default TopicBlogList;
