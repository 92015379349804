import React, { useState, useEffect } from 'react';
import { Link, useNavigate  } from 'react-router-dom';

const PortfolioSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [setTotalCount] = useState(0);
  const [featuredPost, setFeaturedPost] = useState(null);
  const history = useNavigate();
  const itemsPerPage = 3;

  useEffect(() => {
    const apiUrl = `https://api.sarinformatics.in/public/pages/blogs/recent_projects.php?page=${currentPage}&per_page=${itemsPerPage}`;
    const recentPostsUrl = `https://api.sarinformatics.in/public/pages/blogs/recent_post.php?page=1&per_page=1`;

    Promise.all([
      fetch(apiUrl).then((response) => response.json()),
      fetch(recentPostsUrl).then((response) => response.json())
    ])
      .then(([data, recentData]) => {
        if (data.status === 200) {
          setBlogs(data.data);
          setTotalCount(data.total_count);
        }
        if (recentData.status === 200) {
          setFeaturedPost(recentData.data[0]);
        }
      })
      .catch((error) => console.error(error));
  }, [currentPage]);

  
  function viewBlog(blog) {
    history.push(`/article/${blog.id}/${blog.title}`);
  }





  function displayBlogs() {
    return blogs.map((blog, index) => (


<div key={index} className="col-lg-4 col-md-6">
<div className="portfolio-box shadow">
  <img src={blog.img_url} alt="" title={blog.title} className="img-fluid" />
  <Link to={`/projects/${blog.id}/${blog.encoded_title}`} style={{ textDecoration: 'none' }} onClick={() => viewBlog(blog)}>
  <div className="portfolio-info">
    <div className="caption">
      <h4>{blog.title}</h4>
      <p>{blog.short_content}</p>
    </div>
  </div>
  </Link>
</div>
</div>
    ));
  }


  

  return (
    <section id="portfolio" className="portfolio">
      <div className="container">
        <div className="row text-center ">
          <h1 className="display-3 fw-bold text-capitalize">Latest work</h1>
          <div className="heading-line"></div>
          
        </div>
       




        <div className="row" id="blogCards">
        
        {displayBlogs()}
      </div>

      {/* <div className="pagination-container">
        {renderPagination()}
      </div> */}

      </div>
    </section>
  );
};

export default PortfolioSection;
