import React from 'react';
import { Helmet } from 'react-helmet';

const SetMetaTitleDescripionAndKeyword = (props) => {
  const { pageTitle, pageDescription, pageKeywords } = props;
  return (
    <div>
      <Helmet>
        <title>{pageTitle} | sarinformatics.in</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />
      </Helmet>
    </div>
  );
}

export default SetMetaTitleDescripionAndKeyword;
