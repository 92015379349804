// Blog.js
import React from 'react';

import Blogs from '../components/Blog/BlogList'

const Blog = () => {
  


  return (
    <div>
      
      <Blogs/>
    </div>
  );
};

export default Blog;
