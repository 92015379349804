import React, { useEffect } from 'react';

const BackToTop = () => {
  useEffect(() => {
    const lightbox = window.GLightbox({
      touchNavigation: true,
      href: 'https://www.youtube.com/watch?v=J9lS14nM1xg',
      type: 'video',
      source: 'youtube', //vimeo, youtube or local
      width: 900,
      autoPlayVideos: true,
    });
    
    return () => {
      // Clean up the lightbox instance
      lightbox.destroy();
    };
  }, []);

  return (
    <>
      <a href="#" className="shadow btn-primary rounded-circle back-to-top">
        <i className="fas fa-chevron-up"></i>
      </a>
      <script src="assets/vendors/js/glightbox.min.js"></script>
      <script src="assets/js/bootstrap.bundle.min.js"></script>
    </>
  );
};

export default BackToTop;
