
import './ShowPresentation.css'; // Import CSS file for styling

import React, { useRef } from 'react';
import { Box, Button } from '@mui/material';


const PowerPointViewer = ({ pptxUrl }) => {
  const iframeRef = useRef(null);

  const goToPreviousSlide = () => {
    iframeRef.current.contentWindow.postMessage('previous', '*');
  };

  const goToNextSlide = () => {
    iframeRef.current.contentWindow.postMessage('next', '*');
  };

  return (
    <Box className="powerpoint-container">
      <iframe
        ref={iframeRef}
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(pptxUrl)}`}
        width="100%"
        height="600px"
        frameborder="0"
        allowfullscreen
        title="PowerPoint Viewer"
      ></iframe>
      
    </Box>
  );
};

export default PowerPointViewer;
