import React, { useState } from 'react';
import './SearchComponent.css'; // Import the CSS file
import { useNavigate } from 'react-router-dom';

const SearchComponent = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate(); // Use the hook to get the navigate function

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    console.log('Search submitted with term:', searchTerm);
    if (searchTerm) {
      navigate(`/search?query=${searchTerm}`); // Use navigate instead of history.push
    }
  };

  return (
    <div className="search-container mb-5">
      <form onSubmit={handleSearchSubmit}>
        <input
          type="text"
          placeholder="Search keyword"
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <button id="searchbtn" type="submit">
          Search
        </button>
      </form>
    </div>
  );
};

export default SearchComponent;
