import React, { useState, useEffect } from 'react';
import HTMLRenderer from './Blog/Renderer';
import './faq.css';

const FAQSection = () => {
  const [faqData, setFaqData] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading

  useEffect(() => {
    fetch('https://api.sarinformatics.in/public/pages/faq.php')
      .then(response => response.json())
      .then(data => {
        setFaqData(data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch(error => {
        console.error('Error fetching FAQ data:', error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  return (
    <section id="home" className="my-5">
      <div className="container">
        {loading ? ( // Check if data is loading
          <div className="text-center">
            <i className="fas fa-spinner fa-spin fa-3x"></i> {/* Font Awesome spinner icon */}
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            {/* Render your content when data is loaded */}
            <div className="row align-items-center pb-5">
              {/* START THE CONTENT FOR THE INTRO */}
              <h1 className="display-3 fw-bold text-capitalize text-center">FAQ</h1>
              <div className="heading-line text-white"></div>
              {faqData && faqData.map(item => (
                <div key={item.id} className="row align-items-center py-5 faq-container">
                  <h2 className="display-7 fw-bold text-capitalize">{item.subtitle}</h2>
                  <span className="display-2--description lh-base text-dark mb-3"><HTMLRenderer htmlString={item.content} /></span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default FAQSection;
