import React, { useState } from 'react';

const RightClickOption = ({ onRightClick, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleContextMenu = (event) => {
    event.preventDefault();
    const clickX = event.clientX;
    const clickY = event.clientY;
    setPosition({ x: clickX, y: clickY });
    setIsVisible(true);
  };

  const handleOptionClick = () => {
    setIsVisible(false);
    onRightClick();
  };

  return (
    <div onContextMenu={handleContextMenu} style={{ position: 'relative' }}>
      {isVisible && (
        <div
          style={{
            position: 'absolute',
            top: position.y,
            left: position.x,
            backgroundColor: '#fff',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
            padding: '5px',
            zIndex: '1000',
          }}
        >
          <button onClick={handleOptionClick}>{children}</button>
        </div>
      )}
    </div>
  );
};

export default RightClickOption;
