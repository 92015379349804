import React from 'react';
import './PaymentGateway.css'; // Import CSS file for styling

const UpiPayment = () => {
  

  return (
    <div id="payment-container" className="payment-container">
      <p className='p-3 bg-warning '><b>For the timebeing we are not using any PAYMENT GATEWAY. If you are interested to buy this project mail us including project URL.</b></p>
       <div className='my-3'>
                <p><strong className='buy-project-subtitle'>Project Source Code: </strong>The complete source code of the project, meticulously organized and documented for easy comprehension and future maintenance.</p>
                <p><strong className='buy-project-subtitle'>Sample Images: </strong>Visual representations or screenshots showcasing the project's interface and functionality.</p>
                <p><strong className='buy-project-subtitle'>Description: </strong>A brief yet comprehensive description outlining how the project operates, its key features, and the technologies utilized.</p>
                <p><strong className='buy-project-subtitle'>Support: </strong>Ongoing technical support to address any queries or issues that may arise during the implementation or customization process.</p>
                <p>Rest assured, you'll have all the necessary resources and assistance to seamlessly integrate the project into your workflow and leverage its benefits effectively.</p>

          </div>
    </div>
  );
};

export default UpiPayment;
