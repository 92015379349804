import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';


import { FaFacebookF, FaWhatsapp, FaShare } from 'react-icons/fa';
import HTMLRenderer from './Renderer';
import './BlogPage.css';
import Badge from 'react-bootstrap/Badge';
import TopicSlider from './TopicSlider';
import SetMetaTitleDescripionAndKeyword from './SetMetaAndTitle';
import Search from '../Search';



const Blog = () => {


  const navigate = useNavigate();

  useEffect(() => {
    // Replace the URL with the formatted title
    window.scrollTo(0, 0);
  }, []);



  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [tags, setTags] = useState([]);
  const [metatags, setMetatags] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const { title } = useParams();
  // const formattedTitle = title.replace(/-/g, ' ');

  // useEffect(() => {
  //   // Replace the URL with the formatted title
  //   const newUrl = `/${formattedTitle}`;
  //   navigate(newUrl, { replace: true });
  // }, [navigate, title]);

  useEffect(() => {
    // Fetch the data for the specified blog post based on the id parameter
    fetch(`https://api.sarinformatics.in/public/blogviewer.php?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setBlog(data.data);
          setSuggestions(data.suggestions);
          if (data.tags && data.tags.tags) {
            const tagsArray = data.tags.tags.split(', ');
            setTags(tagsArray);
            setMetatags(data.tags.tags);
          }
        } else {
          console.error('Error fetching blog data');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false); // Set loading to false when data fetching is complete
      });
  }, [id]);

  if (loading) {
    return <div className="text-center"> <i className="fas fa-spinner fa-spin fa-3x"></i> Loading...</div>; // Render a loading message or spinner
  }

  if (!blog) {
    return <div>Blog Not Found</div>;
  }

  function copyLink() {
    const link = window.location.href;

    const dummyLink = document.createElement('input');
    document.body.appendChild(dummyLink);
    dummyLink.value = link;
    dummyLink.select();
    document.execCommand('copy');
    document.body.removeChild(dummyLink);

    const copyButton = document.querySelector('#copyLinkButton');
    copyButton.textContent = 'Link Copied';
    copyButton.disabled = true;

    setTimeout(function () {
      copyButton.textContent = 'Copy Link';
      copyButton.disabled = false;
    }, 5000);
  }

  const htmlString = blog.content;

  return (
    <div>

      <SetMetaTitleDescripionAndKeyword
        pageTitle={blog.title}
        pageDescription={blog.title}
        pageKeywords={metatags}
      />
      <div className="container my-5">
        <h1 className="display-6 fw-bold text-capitalize text-dark mt-3">{blog.title}</h1>
        <p className="blog-date">{blog.updated_at}</p>
        <hr></hr>
        <div className="row">
          <div className="col-md-3 mt-3">
            <div className="ad-placeholder">Left Ad</div>
          </div>

          <div className="col-md-6">
           
            <div className="blog-content" id="paragraph">
              <HTMLRenderer htmlString={blog.subtitle} />
            </div>

            <img
              src={blog.img_url}
              className="blogimage mt-4 mb-3"
              alt="Banner"
            />

            <div className="blog-content" id="paragraph">
              <HTMLRenderer htmlString={htmlString} />
            </div>

            <div className="my-4">
              <h6>Share this post:</h6>
              <div className="my-3">
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                    window.location.href
                  )}`}
                  className="sharebutton"
                >
                  <FaFacebookF />
                </a>

                <a
                  href={`https://api.whatsapp.com/send?text=Check%20out%20this%20awesome%20blog%20post:%20${blog.title}%20${window.location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="sharebutton"
                >
                  <FaWhatsapp />
                </a>
                <a
                  className="sharebutton"
                  onClick={copyLink}
                  id="copyLinkButton"
                >
                  <FaShare />
                </a>
              </div>
            </div>
            <h4 className="blog-side-header mt-5 mb-3">Tags</h4>
            <div>
              <div className="tag-container">
                {tags.map((tag, index) => (
                  <span key={index} className="badge mr-2">
                    <Link className="link" to={`/search?query=${tag}`}>
                      {tag}
                    </Link>
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="col-md-3 sidebar mt-3 ">
            <Search/>
            <h5 className='px-2 text-primary'>Related Post</h5>
            {suggestions.map((suggestion) => (
              <p className="related-posts suggestions" key={suggestion.id}>
                <Link
                  className="px-0"
                  target="_blank"
                  to={`/article/${suggestion.id}/${suggestion.encoded_title}`}
                >
                  {suggestion.title}
                </Link>
              </p>
            ))}
            <h5 className='px-2 mt-4 mb-3 text-primary'>Categories</h5>
            <div className='px-2'>
              <TopicSlider />
            </div>


            <div className="ad-placeholder">Right Ad</div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Blog;
