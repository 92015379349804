import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Search from './Search'

const SearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(location.search).get('query');
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);


  

  useEffect(() => {
    // Make an API call using the search query and update the searchResults state
    // You can use the API you provided earlier.
    // For example:
    fetch(`https://api.sarinformatics.in/public/pages/blogs/blog_search.php?search_key=${query}&offset=0&perPage=10`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 200) {
          setSearchResults(data.data);
          setTotalCount(data.total_count);
        }
      })
      .catch((error) => console.error(error));
  }, [query]);


  function handlePageChange(page) {
    setCurrentPage(page);
  }
  function renderPagination() {
    if (searchResults.length === 0 || totalCount <= itemsPerPage) return null;

    const totalPages = Math.ceil(Number(totalCount) / itemsPerPage);
    const pageNumbers = [];

    // Display the current page, the previous page, and the next page
    if (currentPage > 1) {
      pageNumbers.push(
        <button
          key="prev"
          className="page-button mr-2 px-4 py-2"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          Previous
        </button>
      );
    }
    pageNumbers.push(
      <button key={currentPage} className={`page-button active px-3 py-2`} onClick={() => handlePageChange(currentPage)}>
        {currentPage}
      </button>
    );
    if (currentPage < totalPages) {
      pageNumbers.push(
        <button
          key="next"
          className="page-button ml-2 px-4 py-2"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Next
        </button>
      );
    }

    return (
      <div className="pagination">
        {pageNumbers}
      </div>
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <main class="container mt-4">
        <div class="row">
          <div class="col-md-9">
            <Search/>
            <h5 className='mb-4 searchkey'>Search Results for "<b>{query}</b>"</h5>

            {searchResults.map((result) => (
              <div key={result.id} >


                <div class="card mb-3 searchresults">

                  <div class="card-body">
                    <h5 class="card-title text-info"><Link className="link" to={`/article/${result.id}/${result.title}`}>{result.title}</Link></h5>
                    <p class="card-text">{result.short_content}</p>
                  </div>
                </div>
              </div>

            ))}


            {/* Pagination Section */}
            <div className="pagination-container">
              {renderPagination()}
            </div>


          </div>
          <div class="col-md-3">
            <div class="sidebar bg-light">
              Advertise
              
              
              
            </div>
          </div>
        </div>
      </main>

    </div>
  );
};

export default SearchResults;
