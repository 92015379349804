import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Spinner } from 'react-bootstrap'; // Import Spinner from react-bootstrap
import Search from '../Search';
import './BlogPage.css'; // Import custom CSS for styling

import SetMetaTitleDescripionAndKeyword from './SetMetaAndTitle';



const BlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [featuredPost, setFeaturedPost] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading

  const itemsPerPage = 9;
  const history = useNavigate();

  useEffect(() => {
    setLoading(true); // Set loading to true when starting to fetch data
    const apiUrl = `https://api.sarinformatics.in/public/pages/blogs/recent_post.php?page=${currentPage}&per_page=${itemsPerPage}`;
    const recentPostsUrl = `https://api.sarinformatics.in/public/pages/blogs/recent_post.php?page=1&per_page=1`;

    Promise.all([
      fetch(apiUrl).then((response) => response.json()),
      fetch(recentPostsUrl).then((response) => response.json())
    ])
      .then(([data, recentData]) => {
        setLoading(false); // Set loading to false when data is fetched
        if (data.status === 200) {
          setBlogs(data.data);
          setTotalCount(data.total_count);
        }
        if (recentData.status === 200) {
          setFeaturedPost(recentData.data[0]);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false in case of error
      });
  }, [currentPage]);

  function displayBlogs() {
    const maxLength = 50; // Adjust the maximum length as needed

    return (
      <div className="row my-3">

<SetMetaTitleDescripionAndKeyword
pageTitle='Blog'
pageDescription='Exploring the evolution and latest trends across various technologies.'
pageKeywords='Technology Trends, Tech Evolution, Latest Innovations, Software Development, Web Development, Mobile Apps, Database Management, Coding Tips, Programming Languages, Tech Insights'
/>

        {blogs.map((blog, index) => (
          <div key={index} className="col-md-4 mb-4 d-flex align-items-stretch">
            <Link to={`/article/${blog.id}/${blog.encoded_title}`} style={{ textDecoration: 'none', width: '100%' }}>
              <Card className="custom-card" style={{ height: '100%', width: '100%', boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px' }}>
                <div className="card-img-container">
                  <Card.Img variant="top" src={blog.img_url} className="card-img" />
                </div>
                <Card.Body className='bg-light'>
                  <Card.Title className="display-2--description card-title">{blog.title.length > maxLength ? `${blog.title.substring(0, maxLength)}...` : blog.title}</Card.Title>
                  <Card.Text className="display-4--description lh-base card-text">{blog.formatted_date}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </div>
        ))}
      </div>
    );
  }

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  function viewBlog(blog) {
    history(`/article/${blog.id}/${encodeURIComponent(blog.title)}`);
  }

  function renderPagination() {
    if (blogs.length === 0) return null;

    const totalPages = Math.ceil(Number(totalCount) / itemsPerPage);
    const pageNumbers = [];

    if (currentPage > 1) {
      pageNumbers.push(
        <button key="prev" className="page-button mr-2 px-4 py-2" onClick={() => handlePageChange(currentPage - 1)}>
          Previous
        </button>
      );
    }
    pageNumbers.push(
      <button key={currentPage} className={`page-button active px-3 py-2`} onClick={() => handlePageChange(currentPage)}>
        {currentPage}
      </button>
    );
    if (currentPage < totalPages) {
      pageNumbers.push(
        <button key="next" className="page-button ml-2 px-4 py-2" onClick={() => handlePageChange(currentPage + 1)}>
          Next
        </button>
      );
    }

    return (
      <div className="pagination">
        {pageNumbers}
      </div>
    );
  }

  return (
    <div className="container my-5">
      <Search />
      {loading ? ( // Render spinner if loading is true
        <div className="text-center my-5">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <div className="row" id="blogCards">
            {displayBlogs()}
          </div>
          <div className="pagination-container">
            {renderPagination()}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogPage;
