import React, { useState, useEffect } from 'react';
import team1 from './Blog/images/team/arijit.jpg';
import team2 from './Blog/images/team/pijush.jpg';
import team3 from './Blog/images/team/debabrata.jpg';
import team4 from './Blog/images/team/sajal.jpg';
import leader from './Blog/images/team/sayandip.jpg'; 
import HTMLRenderer from './Blog/Renderer';
import SetMetaTitleDescripionAndKeyword from './Blog/SetMetaAndTitle';

const HomeSection = () => {
  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://api.sarinformatics.in/public/pages/about.php')
      .then(response => response.json())
      .then(data => {
        setAboutData(data.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of error
      });
  }, []);

  return (
    <section id="home" className="my-5">
      <div className="container">
        {loading ? ( // Check if data is loading
          <div className="text-center">
            <i className="fas fa-spinner fa-spin fa-3x"></i> {/* Font Awesome spinner icon */}
            <p>Loading...</p>
          </div>
        ) : (
          <div>
            {/* Render your content when data is loaded */}
            <div className="row align-items-center pb-5">
              {/* START THE CONTENT FOR THE INTRO */}
              <SetMetaTitleDescripionAndKeyword
        pageTitle='About Us'
        pageDescription='Building Mighty Websites, Affordable Prices'
        pageKeywords='about, sarinformatics.in'
      />
              <h1 className="display-3 fw-bold text-capitalize text-center">About Us</h1>
              <div className="heading-line text-white"></div>
              {aboutData && aboutData.map(item => (
                <div key={item.id} className="row align-items-center pb-5">
                  <h2 className="display-5 fw-bold text-capitalize text-dark mt-3">{item.subtitle}</h2>
                  <span className="display-2--description lh-base text-dark mb-3"><HTMLRenderer htmlString={item.content} /></span>
                </div>
              ))}
              <div className="col-md-6 intros text-start my-4">
                <h1 className="display-2">
                  <span className="display-5--intro text-dark">Sayandip</span>
                  <span className="display-2--description lh-base text-dark">
                    Sayandip Sar pursued MCA from Haldia Institute of Technology and Graduated from Vidyasagar University on Computer Science Honource having experience more than three years in Industry.
                  </span>
                </h1>
              </div>
              {/* START THE CONTENT FOR THE VIDEO */}
              <div className="col-md-6 intros text-end">
                <div className="video-box">
                  <div className="rounded-circle-outline">
                    <img src={leader} alt="Team Member 1" className="img-fluid rounded-circle mb-3" />
                  </div>
                </div>
              </div>
            </div>
            <h1 className="display-3 fw-bold text-capitalize text-center">Team</h1>
            <div className="heading-line text-white"></div>
            <span className="display-2--description lh-base text-dark"></span>
            <div className="row justify-content-center my-4">
              <div className="col-md-3 text-center">
                <div className="rounded-circle-outline">
                  <img src={team1} alt="Team Member 1" className="img-fluid rounded-circle mb-3" />
                </div>
                <h4>Arijit Das</h4>
              </div>
              <div className="col-md-3 text-center">
                <div className="rounded-circle-outline">
                  <img src={team2} alt="Team Member 2" className="img-fluid rounded-circle mb-3" />
                </div>
                <h4>Pijush Kanti Barik</h4>
              </div>
              <div className="col-md-3 text-center">
                <div className="rounded-circle-outline">
                  <img src={team3} alt="Team Member 3" className="img-fluid rounded-circle mb-3" />
                </div>
                <h4>Debabrata Maity</h4>
              </div>
              <div className="col-md-3 text-center">
                <div className="rounded-circle-outline">
                  <img src={team4} alt="Team Member 4" className="img-fluid rounded-circle mb-3" />
                </div>
                <h4>Sajal Jana</h4>
                <p></p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default HomeSection;
