import React, { useState } from 'react';
import UpiPayment from './payment/PaymentGateway'; // Assuming UpiPayment component is in the same directory
import "./BuyNowLiveViewBtn.css";


const BuyNowLiveViewButton = ({ projecturl }) => {
  const [showPayment, setShowPayment] = useState(false);
  

  const handleLiveViewClick = () => {
    // Change the URL to the desired external link
    window.open(projecturl, '_blank');
  };

  const handleBuyNowClick = () => {
    setShowPayment(true);
  };

  return (
    <div>
      <button className="buy-now-button my-3" onClick={handleLiveViewClick}>Live View</button>
      {!showPayment ? (
        <button className="buy-now-button mx-3" onClick={handleBuyNowClick}>Buy Project</button>
      ) : (
        <UpiPayment/>
      )}
    </div>
  );
};

export default BuyNowLiveViewButton;
