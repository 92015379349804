// Homepage.js

import React, { useEffect } from 'react';
import Intro from '../components/Intro'; 
import Services from '../components/Services'; 
import Testimonials from '../components/Testimonial';
import Portfolio from '../components/PortfolioHome'
import Contact from '../components/Contact'

const Homepage = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Intro/>
      <Services/>
      <Testimonials/>
      
      <Portfolio/>
      <Contact/>
    </div>
  );
};

export default Homepage;
