// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Blog from './pages/Blog';

import BlogPost from '../src/components/Blog/BlogPost'
import Services from '../src/components/Services'
import Contact from './components/Contact'
import Portfolio from '../src/components/Portfolio'
import Faq from './components/Faq'
import SearchResults from './components/SearchResults';

import ProjectViewer from './components/Blog/ProjectViewer';
import About from '../src/components/About';
import Script from '../src/components/Script';
import Disclaimer from '../src/components/Disclaimer';
import Privacy from '../src/components/Privacy';
import CookieBanner from './components/Cookies'; // Import CookieBanner component
import ServicePopup from './components/BusinessPopup';
import RightClickOption from './components/RightClickOptions';
import TopicList from './components/Blog/TopicList';
import ProgrammingAndTopic from './components/Blog/Codebook';


const App = () => {
  const handleRightClick = () => {
    alert('Right-click option clicked!');
  };
  return (
    <Router>
      {/* <CookieBanner />  */}
      <RightClickOption onRightClick={handleRightClick}>Custom Option</RightClickOption>
      <Header />
      
      <ServicePopup/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About/>} />
        <Route path="/disclaimer" element={<Disclaimer/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/portfolio" element={<Portfolio/>} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/faq" element={<Faq/>} />
        <Route path="/article/:id/:title" element={<BlogPost/>} />
        <Route path="/topic/:topicname" element={<TopicList/>} />
        <Route path="/:id/:title" element={<BlogPost/>} />
        <Route path="/projects/:id/:title" element={<ProjectViewer/>} />
        <Route path="/codebook" element={<ProgrammingAndTopic/>} />
        <Route path="/search" element={<SearchResults/>} />
      </Routes>
      <Footer />
      <Script/>
    </Router>
  );
};

export default App;
