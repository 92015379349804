import React from 'react';


const ContactSection = () => {
  return (
    <section id="contact" className="get-started">
      <div className="container">
        <div className="row text-center">
          <h1 className="display-3 fw-bold text-capitalize">Contact Us</h1>
          <div className="heading-line"></div>
          <p className="lh-lg">
          Your Success is Our Priority, Guaranteed Satisfaction Every Time.
          </p>
        </div>

        {/* START THE CTA CONTENT  */}
        <div className="row text-white">
          <div className="col-12 col-lg-6 gradient shadow p-3">
            <div className="cta-info w-100">
              <h4 className="display-4 fw-bold">100% Satisfaction Guaranteed</h4>
              <p className="lh-lg">
              Exceeding Expectations, Every Step of the Way. </p>
              <h3 className="display-3--brief">What will be the next step?</h3>
              <ul className="cta-info__list">
                <li>We'll prepare the proposal.</li>
                <li>we'll discuss it together.</li>
                <li>let's start the discussion.</li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-6 bg-white shadow p-3">
            <div className="form w-100 pb-2">
              <h4 className="display-3--title mb-5">start your project</h4>
              
              <div className='row text-dark'>
              <div className="col-lg-6 col-md mb-3">
                  <p><b>Mail Us: </b><br></br>contact@sarinformatics.in</p>
                  <p><b>Call Us: </b><br></br>+91 9679337579</p>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
