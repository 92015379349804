import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import '../components/Blog/assets/css/style.css';
import '../components/Blog/assets/css/fontawesome.css';

const MyNavbar = () => {
    const handleNavLinkClick = (event) => {
        const navLinks = document.querySelectorAll('.nav-link');
        navLinks.forEach(link => {
            link.classList.remove('active');
        });
        event.target.classList.add('active');
    };

    return (
        <>
            <Navbar bg="primary" variant="dark" expand="lg">
                <Container>
                    <Navbar.Brand href="/">
                        <img
                            src="https://api.sarinformatics.in/portfolio/public/images/sar_informatics.png"
                            height="65"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav>
                            <Nav.Link className='nav-link text-white' href="/about" onClick={handleNavLinkClick}>About</Nav.Link>
                            <Nav.Link className='nav-link text-white' href="/services" onClick={handleNavLinkClick}>Services</Nav.Link>
                            <Nav.Link className='nav-link text-white' href="/contact" onClick={handleNavLinkClick}>Contact</Nav.Link>
                            <Nav.Link className='nav-link text-white' href="/blog" onClick={handleNavLinkClick}>Blog</Nav.Link>
                            <Nav.Link className='nav-link text-white' href="/portfolio" onClick={handleNavLinkClick}>Portfolio</Nav.Link>
                            <Nav.Link className='nav-link text-white' href="/codebook" onClick={handleNavLinkClick}>Codebook</Nav.Link>
                            {/* New Option */}
                            <Nav.Link className='nav-link text-white' href="/blog" onClick={handleNavLinkClick}>
                                <FontAwesomeIcon icon={faSearch} /> {/* Search Icon */}
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default MyNavbar;
